<script setup="">
import AppBackground from "@/components/AppBackground.vue";
import { openNavigation } from "@/plugins/navigationPlugin.js";
import { onBeforeMount, onMounted, ref } from "vue";
import { usePreloaderStore } from "@/stores/preloader.js";
import router from "@/router/index.js";
import axiosInstance from "@/api.js";
import { useMetaStore } from "@/stores/meta.js";
import { useGlobalPopupStore } from "@/stores/popup.js";

const globalPreloader = usePreloaderStore()
const globalPopup = useGlobalPopupStore()
const metaStore = useMetaStore()
metaStore.setTitle('Правила игры')

const titles = [
  {name: 'История', link: 'history'},
  {name: 'Обзор', link: 'review'},
  {name: 'Процесс игры', link: 'process'},
  {name: 'Количество игроков', link: 'numberOfPlayers'},
  {name: 'Раунд игры', link: 'round'},
  {name: 'Ваш ход', link: 'yourTurn'},
  {name: 'Коллективное обсуждение', link: 'discussion'},
  {name: 'Голосование', link: 'voting'},
  {name: 'Победа в игре', link: 'winCondition'},
  // {name: 'Характеристика персонажа', link: 'characterDescription'},
]
const navBlock = ref()
const pageHTML = ref(`<h1 class="main-rules-p__title">Правила игры</h1>
          <p class="main-rules-p__description">Могли бы вы себе представить как это пережить глобальную катастрофу?
                                             Думаю
                                             что нет... Для этого был создан «Бункер Онлайн!» чтобы вы смогли ощутить
                                             как это! Наша игра очень простая, и на изучение правил вам не понадобится
                                             много времени! Уже после первой игры вы будете полностью понимать как
                                             играть.Так же перед началом игры советуем вам приготовить вкусный чай или
                                             кофе, взять печеньки и погрузится с головой в игру!
          </p>
          <div class="main-rules-p__body">
            <ul class="main-rules-p__mainList">
              <li id="history">
                <h2>История</h2>
                <p>
                  Могли бы вы себе представить как это пережить глобальную катастрофу? Думаю что нет... Для этого был
                  создан
                  «Бункер Онлайн!» чтобы вы смогли ощутить как это! Наша игра очень простая, и на изучение правил вам не
                  понадобится много времени! Уже после первой игры вы будете полностью понимать как играть.Так же перед
                  началом игры советуем вам приготовить вкусный чай или кофе, взять печеньки и погрузится с головой в
                  игру!
                </p>
              </li>
              <li id="review">
                <h2>Обзор</h2>
                <h3>Катаклизм</h3>
                <p>Описание текущего для игры катаклизма. Как это произошло, что случилось и четкое понимание того, с
                   чем
                   связаны
                   проблемы, что даст вам понять в процессе игры кто среди людей вам подходит, а кого нужно выгнать.</p>
                <h3>Бункер</h3>
                <p>Описание найденного бункера. Единственный шанс, чтобы выжить в случае катаклизма - это попасть в
                   бункер.
                   У
                   вас
                   есть информация о времени его постройки, местонахождении и данные о спальных комнатах.Так же вам
                   доступна
                   следующая информация:- Размер бункера — общая площадь убежища.- Время нахождения — сколько вам
                   потребуется
                   времени, чтобы пережить катастрофу.- Количество еды — запас продуктов, которых вам должно хватить на
                   период
                   пребывания в бункере. В случае, если продуктов недостаточно или отсутствуют совсем, игроки будут
                   вынуждены
                   выходить из бункера во время катастрофы, чтобы добыть пищу.- В бункере имеется — те вещи, которые
                   находятся
                   внутри бункера и которые могут быть полезны при выживании.В зависимости от того, что находится в
                   бункере,
                   вам
                   так же предстоит определить кто из выживших будет более полезен, учитывая данные обстоятельства.</p>
                <h3>Описание персонажа</h3>
                <p>Описание вашего персонажа. Ваш герой состоит из следующих характеристик:</p>
                <ul>
                  <li>
                    <a href="/">Пол</a>
                  </li>
                  <li>
                    <a href="/">Телосложение</a>
                  </li>
                  <li>
                    <a href="/">Человеческая черта</a>
                  </li>
                  <li>
                    <a href="/">Специальность</a>
                  </li>
                  <li>
                    <a href="/">Здоровье</a>
                  </li>
                  <li>
                    <a href="/">Хобби/Увлечение</a>
                  </li>
                  <li>
                    <a href="/">Фобия/Страх</a>
                  </li>
                  <li>
                    <a href="/">Инвентарь</a>
                  </li>
                  <li>
                    <a href="/">Дополнительное сведение</a>
                  </li>
                  <li>
                    <a href="/">Спец. возможность #1</a>
                  </li>
                  <li>
                    <a href="/">Спец. возможность #2</a>
                  </li>
                </ul>
                <a href="/">(см. Характеристики персонажа)</a>
                <h3>Видео чат</h3>
                <p>Видео чат с помощью которого игроки могут общаться. Для того, чтобы использовать видео чат, у вас в
                   начале
                   работы появится оповещение о разрешении использовании вашего микрофона и веб камеры в браузере. После
                   того как
                   вы дадите свое разрешение, функционал будет полностью рабочий.Если же у вас возникли проблемы -
                   обновите
                   страницу и попробуйте снова, если не получилось тогда найдите информацию как разрешить использование
                   микрофона
                   и веб камеры для своего браузера (инструкция Google Chrome). Если проблема сохраняется - напишите
                   нам!В
                   видео
                   чате можно включить/выключить веб камеру, микрофон. Так же доступны опции как выключить всем
                   микрофон,
                   оповестить всех, что вы хотите что-то сказать и много других полезных вещей для игры.</p>
                <h3>Заметки</h3>
                <p>Место для заметок, которые можно оставлять в процессе игры.</p>
                <h3>Панель ведущего</h3>
                <p>Представляет собой набор функционала для использования специальных и профессиональных возможностей
                   игроков, а
                   так же возможность управлением временным лагерем и таймером.</p>
              </li>
              <li id="process">
                <h2>Процесс игры</h2>
                <p>
                  В первом игровом круге все начинается с представления друг друга (см. Раунд игры).В процессе
                  знакомства
                  игрок раскрывает свой основной параметр — это специальность (см. Характеристики персонажа), и
                  характеристики в зависимости от количества игроков (см. Количество игроков)На это дается каждому
                  игроку
                  по 1 минуте (см. Ваш ход).После того как каждый игрок рассказал о своем параметре, игроки получают 1
                  минуту общего времени на коллективное обсуждение (см. Коллективное обсуждение).После этого приходит
                  время голосования. Игроки должны выбрать того, кто покинет временный лагерь и не попадет в бункер.
                  Разглашать еще не открытые параметры в целях переубеждения игроков - запрещается (см.
                  Голосование).После
                  голосования начинается следующий игровой круг (раунд) (см. Раунд игры).Во всех последующих раундах
                  раскрывается только один параметр, вне зависимости от количества игроков.В конце игры игроки, которые
                  попали в бункер, раскрывают свои характеристики. Ведущий подводит итог (см. Победа в игре).
                </p>
              </li>
              <li id="numberOfPlayers">
                <table>
                  <thead>
                  <tr>
                    <th>Количество игроков</th>
                    <th>Количество игроков</th>
                    <th>Характеристик в первый раунд (исключая специальность)</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>6-7</td>
                    <td>3</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>8-9</td>
                    <td>4</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>10-11</td>
                    <td>5</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>12-13</td>
                    <td>6</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>114-15</td>
                    <td>7</td>
                    <td>1</td>
                  </tr>
                  </tbody>
                </table>
              </li>
              <li id="round">
                <h2>Раунд игры</h2>
                <p>
                  Первый раунд ходят по часовой стрелке, начиная с первого игрока который нашел бункер (Цифра в блоке
                  "Желающие попасть в бункер").Следующий раунд игроки начинают ходить в обратном порядке, против часовой
                  стрелки.Принцип последующих раундов так и продолжается, каждый новый раунд — новый порядок начала
                  хода.Когда все игроки сделали свои ходы, ведущий игры (лидер во временном лагере) оглашает результаты
                  голосования (см. Голосование) за исключение игрока из временного лагеря.Так продолжается до тех пор,
                  пока не останется допустимое количество игроков (см. Количество игроков) в бункер.
                </p>
              </li>
              <li id="yourTurn">
                <h2>Ваш ход</h2>
                <p>
                  Ваш ход — самое время блеснуть! Вам дали время представить своего персонажа.Делайте игру интересной,
                  включайте фантазию на максимум и расскажите свою историю!Не нужно просто зачитывать свои
                  характеристики
                  без эмоций, так игра будет не интересной.Помните — вы любым образом должны попасть в бункер!В
                  зависимости от количества игроков, в первый раунд игры вы открываете нужное количество характеристик.
                  (см. Количество игроков)Во все последующие ходы вы открываете по одной характеристике за ход (раунд).
                </p>
              </li>
              <li id="voting">
                <h2>Голосование</h2>
                <p>
                  В игре есть голосование за исключение человека из временного лагеря.Голосование - обязательный процесс
                  игры. Оно проводится в конце игрового раунда и оглашает голосование ведущий игры, он же лидер в
                  лагере.
                  <br> Если большинство игроков решили не голосовать (например недостаточно информации об игроках) в
                  текущем раунде — голосование автоматически объявляется закрытым, и игроки переходят в следующий раунд.
                  В
                  таком случае, в следующем раунде после окончания коллективного обсуждения (см. Коллективное
                  обсуждение),
                  игроки обязательно должны провести голосование. Процесс голосования будет проходить 2 раза, чтобы
                  выгнать сразу 2‑х игроков. Переход в новый раунд без исключения игроков — невозможен. <br> Пропускать
                  голосование можно только 1 раз за игру (и не в последнем кругу)! <br> Если игроки решили провести
                  голосование, тогда ведущий игры дает каждому игроку по 30 сек. на высказывание перед тем как запустить
                  голосование (очередь идет по порядку от начала таблицы “Желающие попасть в бункер”).Время на
                  высказывание вы можете потратить на объяснения за кого нужно проголосовать или же дополнительно
                  защитить
                  себя от голосов против вас, так же вы можете просто дать общий комментарий.Игрок может отказаться от
                  высказывания, в таком случае слово переходит к следующему игроку без дополнительного времени. <br>
                  После
                  всех высказываний ведущий игры запускает голосование.На голосование у игроков есть 15 сек. Так же
                  запрещаются любые комментарии до момента завершения голосования.Ведущий игры может принудительно
                  завершить голосование по истечению времени.В случае, если игрок нарушает правило тишины - на следующем
                  раунде игрок теряет возможность высказывания перед началом голосования.В случае, если игрок не принял
                  участие в голосовании, его голос идет против себя. <br> Когда все голоса собраны есть несколько
                  вариантов развития событий:1) Один игрок набрал больше чем 70% голосов — исключение из лагеря без
                  оправдания.2) Один игрок набрал наибольшее количество голосов, но при этом общий процент составляет
                  меньше 70% - игрок получает 30 сек. на оправдание.3) Несколько игроков получили наибольшее одинаковое
                  количество голосов — каждый из этих игроков получает 30 сек. на оправдание. <br> После завершения
                  оправданий каждого из игроков ведущий игры начинает новое голосование с такими же правилами.Возможные
                  варианты после повторного голосования:1) Если среди игроков, которые были на оправдании изменилось
                  количество голосов — исключить из лагеря игрока, который набрал больше всех голосов.2) В случае, если
                  другой игрок получил одинаковое количество голосов с кандидатом на вылет — новый кандидат на вылет
                  получает 30 сек. на оправдание.3) В случае, если кандидаты на вылет получили одинаковое количество
                  голосов - голосование объявляется закрытым и игроки переходят в следующий круг.4) В случае, если
                  другой
                  игрок получил больше чем 70% голосов — исключение из лагеря.5) В случае, если другой игрок получил
                  наибольшее количество голосов и при этом общий процент составляет меньше 70% — игрок получает 30 сек.
                  на
                  оправдание. <br> * За один раунд голосования игрок может только один раз получить оправдательную
                  речь.*
                  Во время оправдания нельзя разглашать свои характеристики (нарушение — исключение из лагеря). <br>
                  После
                  финального завершения голосования (когда кандидаты на исключение определены) ведущий игры объявляет
                  прощальную речь для каждого из игроков, который покидает временный лагерь.На прощальную речь у каждого
                  игрока есть 15 сек. За это время игрок или другие игроки могут использовать спец. возможности и
                  возможности от специальности.После завершения 15 сек. игрок покидает временный лагерь и больше не
                  может
                  принимать участие в игре (раскрывать характеристики или использовать любые игровые возможности). Не
                  стоит забывать о том, что в игре есть возможность вернуть игрока в лагерь. <br> * На прощальной речи
                  использовать карты, которые влияют на изменение хода голосования невозможно.
                </p>
              </li>
              <li id="discussion">
                <h2>Коллективное обсуждение</h2>
                <p>
                  Во время общего обсуждения каждый игрок имеет возможность что-то сказать. Здесь нет ограничений на
                  каждого человека. Общее обсуждение длится 1 минуту.
                </p>
              </li>
              <li id="winCondition">
                <h2>Победа в игре</h2>
                <p>
                  По завершению последнего голосования, когда определилось нужное количество людей для прохождения в
                  бункер, игра завершается. Игроки, которые попали в бункер и переживут катаклизм - считаются
                  победителями! Остальные игроки так и остаются возле лагеря поскольку искать другой бункер - нет смысла,
                  ведь ситуация с катаклизмом совсем критическая. Некоторые потеряли надежду и решили погибнуть на
                  месте, часть все-таки решила испытать удачу и попытаются выжить без бункера...
                </p>
              </li>
              <li id="characterDescription">
                <h2>Характеристики персонажа</h2>
                <p>
                  У каждого персонажа есть несколько характеристик, которые он получает в начале игры.Любой из
                  параметров можно изменить используя специальную возможность, которая это позволяет сделать.<br>
                  Специальная возможность<br>
                  Каждую спец. возможность можно использовать только один раз за игру, но в любой момент игры (после
                  исключения игрока из лагеря - запрещается этому игроку использовать спец. возможности).Каждая
                  последующая спец. возможность не имеет привязки к предыдущей спец. возможности, например: если игрок
                  использовал возможность "Аннулировать все специальности", после этого текущий или любой другой игрок
                  может использовать возможность, которая генерирует для всех или для конкретного игрока
                  специальность.Их можно использовать в любой момент игры или в зависимости от описания действия.Как
                  использовать спец. возможность?Прежде всего вам нужно сказать, что вы используете спец. возможность
                  (для этого в любой момент игры нужно произнести слова "стоп игра", использую свою
                  спец.возможность).После этого игрок зачитывает свою спец. возможность. Ведущий выполняет необходимые
                  изменения.<br>
                  Специальность<br>
                  Специальность — есть специальности, которые дают персонажу дополнительные бонусы (см. Профессиональные
                  возможности). Их можно использовать в любой момент игры или в зависимости от описания действияУ каждой
                  специальности есть свой стаж работы:<br>
                  Дилетант — до 1 месяца<br>
                  Стажер — от 1 до 3 месяцев<br>
                  Новичок — от 4 месяцев до 1 года<br>
                  Любитель — от 1 до 2 лет<br>
                  Опытный — от 2 до 5 лет<br>
                  Эксперт — от 5 до 10 лет<br>
                  Профессионал — от 10 лет<br>
                  Чайлдфри — статус человека, при котором он категорически против любого участия в продолжении рода. По
                  своей воле человек не будет участвовать в продолжении рода. При попытке насильно заставить или
                  переубедить, человек может получить непоправимую психологическую травму.Если женщина в возрасте 50 лет
                  или больше - она не может иметь детей. При телосложении "Хрупкое" или "Ожирение-сильное" - родить она
                  сможет только с помощью специалистов (определенных профессий), которые могут решать проблемы с
                  родами.Если мужчина в возрасте 60 лет и больше - он не может иметь детей. При телосложении
                  "Ожирение-сильное" шанс на продолжение рода может быть только с помощью специалистов (определенных
                  профессий).Также, если возраст мужчины больше 45 лет шанс того, что ребенок будет здоровый
                  уменьшается.Присутствуют следующие этапы жизни: молодой, взрослый и пожилой.У характеристики
                  "Здоровье" есть разные степени проблем: легкая, средняя, тяжелая и критическая.Уровень хобби
                  оценивается следующим образом:<br>
                  Дилетант — до 1 месяца<br>
                  Начинающий — от 1 до 4 месяцев<br>
                  Новичок — от 5 месяцев до 1 года<br>
                  Любитель — от 1 до 2 лет<br>
                  Продвинутый — от 2 до 5 лет<br>
                  Мастер (гуру) — больше 5 лет<br>
                </p>
              </li>
            </ul>
          </div>
`)

onBeforeMount(async () => {
  globalPreloader.activate()

  try {
    let pageHTMLData = await axiosInstance.get('/staticPage/rules')
    if(pageHTMLData.data.html) {
      pageHTML.value = pageHTMLData.data.html
    }
  } catch(e) {
    globalPopup.activate('Ошибка','При загрузке правил игры с сервера произошла ошибка','red')
  }

  globalPreloader.deactivate()
})

</script>

<template>
  <main class="rules-p">
    <div class="rules-p__wrapper">
      <span>
        <AppBackground img-name="await.jpg" />
      </span>
    </div>
    <div class="rules-p__container">
      <div class="rules-p__body">
        <div @click="openNavigation(navBlock)" class="pagination-rules-p">
          <div ref="navBlock" class="pagination-rules-p__block linear-border white">
            <ul class="pagination-rules-p__list">
              <li class="pagination-rules-p__item"
                  v-for="item in titles"
                  :key="item.title"
              >
                <router-link class="pagination-rules-p__link" :to="`#${item.link}`">{{ item.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="main-rules-p" v-html="pageHTML">
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
@import '@/assets/scss/style';

.rules-p {
  padding-top: 130px;
  position: relative;
  z-index: 5 !important;
  line-height: 1.5;

  &__container {
  }

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    span {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 25fr 75fr;
    gap: 30px;

    @media (max-width: $mobile) {
      display: flex;
    }
  }
}

.pagination-rules-p {
  position: relative;

  @media (max-width: $mobile) {
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    font-size: 11px;
    font-weight: 600;
    top: 50%;
    transform: translate(0, -50%);
    padding: 20px 0;
    width: 30px;
    height: 405px;
  }

  &__block {
    padding: 30px 23px !important;
    position: fixed !important;
    max-width: 23% !important;

    @media (max-width: $mobile) {
      position: absolute !important;
      display: flex !important;;
      max-width: 100% !important;;
      transform: translateX(-95%) !important;;
      transition: transform 0.2s ease !important;;
      width: 216px !important;;
      min-width: 216px !important;;
      left: 0 !important;;
      top: 0 !important;;

      &._active {
        transform: translateX(15px) !important;;
      }
    }
  }

  &__list {

    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    margin-bottom: 20px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  &__link {


    &._active, &:hover {
      background: linear-gradient(180deg, #F9D35B, #D96613);
      background-clip: border-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.main-rules-p {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 5px;

  @media (max-width: $mobile) {
    flex: 1 1 auto;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.4;
    border-radius: 6px;
    filter: blur(110.5px);
    z-index: -1;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    background: linear-gradient(90deg, rgb(249, 211, 91), rgb(217, 102, 19) 25%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__body {
  }

  h2, h3 {
    font-weight: 700;
  }


  h2 {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 14px;
    line-height: 1.8;
  }

  p {
    line-height: 1.8;
    color: #e5e5e5;
  }

  ul {
    & > li {
      margin-bottom: 50px;
      overflow-x: auto;

      &:last-child {
        margin-bottom: 0;
      }

      & > ul {
        li {
          margin-bottom: 6px;
          overflow-x: inherit;

          &:last-child {
            margin-bottom: 6px;
          }

          &::before {
            content: '- ';
          }
        }
      }
    }
  }

  &__mainList {


  }

  a {
    text-decoration: underline !important;
    color: #d7b14b !important;

    @media (any-hover: hover){
      &:hover{
        text-decoration: none !important;
      }
    }
  }

  table {
    width: 100%;
    background-color: #00000080;
    padding: 15px;
    border-collapse: collapse;
    border: 1px solid #181818;
    border-radius: 8px;

    caption {
      font-size: 20px;
      margin-bottom: 15px;
      font-weight: 700;
    }

    tbody {
      tr {
        border-bottom: 1px solid #181818;
      }

      td {
        text-align: center;
        padding: 12px;
        border-right: 1px solid #181818;
      }
    }

    thead {
      th {
        border-bottom: 3px solid #181818;
        border-right: 1px solid #181818;
        padding: 13px;
        font-weight: 700;
        font-size: 13px;
        text-align: center;
      }
    }
  }
}

//.table {
//  display: flex;
//  flex-direction: column;
//  overflow-x: auto;
//  min-width: 530px;
//
//  &__body {
//  }
//
//  &__row {
//    display: grid;
//    grid-template-columns: 25fr 25fr 50fr;
//    border-bottom: 1px solid #181818;
//
//    &:first-child {
//      border-top: 1px solid #181818;
//    }
//  }
//
//  &__column {
//    padding: 10px;
//    border-right: 1px solid #181818;
//
//    &:first-child {
//      border-left: 1px solid #181818;
//    }
//  }
//}

</style>