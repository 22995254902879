import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getRandomInt } from "@/plugins/functions.js";
import axiosInstance from "@/api.js";

export const useMainStore = defineStore('mainStore', () => {
  const systemSettings = ref({
    version: import.meta.env.VITE_FRONT_VERSION
  })
  const randNumFromBanner = ref(getRandomInt(1, 4))
  const showVersionPopup = ref(false)
  
  function verifyFrontVersion() {
    axiosInstance.get('/frontVersion').then((response) => {
      console.log(systemSettings.value.version,response.data)
      console.log(isNewerVersion(systemSettings.value.version,response.data))
      if (isNewerVersion(systemSettings.value.version,response.data)) {
        showVersionPopup.value = true
      }
    })
  }
  
  return {
    systemSettings,
    randNumFromBanner,
    showVersionPopup,
    verifyFrontVersion
  }
})

function isNewerVersion(oldVer, newVer) {
  const oldParts = oldVer.split('.')
  const newParts = newVer.split('.')
  for (let i = 0; i<newParts.length; i++) {
    const a = parseInt(newParts[i]) || 0
    const b = parseInt(oldParts[i]) || 0
    if (a>b) return true
    if (a<b) return false
  }
  return false
}