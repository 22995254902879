import {ref} from 'vue'
import {defineStore} from 'pinia'
import {getLocalData, removeLocalData, setLocalData} from "@/plugins/functions.js";
import axiosInstance from "@/api.js";

export const useGlobalPopupStore = defineStore('globalPopup', () => {
  const show = ref(false)
  const title = ref('')
  const text = ref('')
  const color = ref('white')
  const isTimer = ref(false)
  let showSeconds = 3
  let timer = setTimeout(() => {
  }, 0)
  
  function activate(title, text, color = 'white', showInTimer = false) {
    if (showInTimer) {
      clearTimeout(timer)
      isTimer.value = true
      timer = setTimeout(() => {
        deactivate()
      }, showSeconds * 1000)
    } else {
      isTimer.value = false
    }
    this.title = title
    this.text = text
    this.color = color
    show.value = true
  }
  
  function deactivate() {
    clearTimeout(timer)
    show.value = false
  }
  
  return {
    show,
    title,
    text,
    activate,
    deactivate,
    color,
    isTimer
  }
})


export const useDBPopupStore = defineStore('DBPopup', () => {
  let localData = getLocalData('DBPopup')
  const title = ref('')
  const text = ref('')
  const color = ref('white')
  const isShow = ref(false)
  const isBoldBorder = ref(false)
  
  async function setPopup() {
    try {
      let DBData = await _downloadPopupData()
      if (DBData) {
        DBData = DBData.data
        if (!DBData.isShow || DBData.hoursToOpen < 1) {
          removeLocalData('DBPopup')
          localData = null
        }
      }
      if (localData && localData.timeToShow
        && localData.timeToShow > new Date().getTime()) {
        return
      }
      
      if (!DBData) {
        return
      }
      if (!!!DBData.isShow) {
        isShow.value = false
        return
      }
      if (+DBData.hoursToOpen < 1) {
        _setValues(true)
      } else {
        _setValues(true)
        
        setLocalData('DBPopup', {
          title: title.value,
          text: text.value,
          color: color.value,
          isShow: isShow.value,
          timeToShow: +new Date() + +DBData.hoursToOpen * 60 * 60 * 1000,
          isBoldBorder: isBoldBorder.value,
        })
      }
      
      function _setValues(showPopup) {
        title.value = DBData.title.toString()
        text.value = DBData.text.toString()
        color.value = DBData.color.toString()
        isBoldBorder.value = !!DBData.isBoldBorder
        isShow.value = showPopup
      }
    } catch (e) {
      console.error('Ошибка установки popup сервера: ', e)
    }
  }
  
  async function _downloadPopupData() {
    try {
      return await axiosInstance.get('/DBPopup')
    } catch (error) {
      console.error(error)
      return null
    }
  }
  
  return {
    setPopup,
    title,
    text,
    color,
    isShow,
    isBoldBorder
  }
})
