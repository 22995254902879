<script setup="">
import {useMainStore} from "@/stores/main.js";
import router from "@/router/index.js";

const {randNumFromBanner} = useMainStore()

</script>

<template>
  <div class="orderGame">
    <div class="orderGame__container">
      <div class="orderGame__block" :class="`_${randNumFromBanner}`">
        <router-link v-if="randNumFromBanner!==4" to="/wiki/events">
          <div class="orderGame__wrapper MOBILE">
            <picture>
              <source media="(min-width:480px)" :srcset="`/img/banners/${randNumFromBanner}.jpg`"/>
              <source :srcset="`/img/banners/mobile/${randNumFromBanner}.png`"/>
              <img :src="`/img/banners/${randNumFromBanner}.jpg`" alt="" :class="`_${randNumFromBanner}`">
            </picture>
          </div>
          <div class="orderGame__body MOBILE" :class="`_${randNumFromBanner}`">
            <div class="orderGame__title title-orderGame" :class="`_${randNumFromBanner}`">
            <span v-if="randNumFromBanner===3" class="title-orderGame__logo" :class="`_${randNumFromBanner}`">
              <img src="/img/logo-white.png" alt="">
            </span>
              <p>BUNKERONLINE</p>
              <span v-if="randNumFromBanner===2" class="title-orderGame__logo" :class="`_${randNumFromBanner}`">
              <img src="/img/logo-header.png" alt="">
            </span>
              <span v-if="randNumFromBanner===1" class="title-orderGame__logo" :class="`_${randNumFromBanner}`">
              <img src="/img/logo-white.png" alt="">
            </span>
            </div>
            <p class="orderGame__text" :class="`_${randNumFromBanner}`">Организация и проведение игр под заказ</p>
            <div class="orderGame__btnBlock" :class="`_${randNumFromBanner}`">
              <a href="" class="orderGame__btn btn gold" :class="`_${randNumFromBanner}`">
                <span class="text">
                  Заказать
                </span>
              </a>
            </div>
          </div>
          <div class="orderGame__pc PC">
            <img :src="`/img/banners/${randNumFromBanner}.jpg`" alt="" :class="`_${randNumFromBanner}`">
          </div>
        </router-link>
        <router-link v-else to="/wiki/events">
          <div class="orderGame__pc PC _4">
            <picture>
              <source media="(min-width:480px)" :srcset="`/img/banners/${randNumFromBanner}.jpg`"/>
              <source :srcset="`/img/banners/mobile/${randNumFromBanner}.jpg`"/>
              <img :src="`/img/banners/${randNumFromBanner}.jpg`" alt="" :class="`_${randNumFromBanner}`">
            </picture>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/style";

.MOBILE {
  @media (min-width: $mobileSmall) {
    display: none !important;
  }
}

.PC {
  max-width: 100%;

  @media (max-width:$mobileSmall){
    display: none;
  }

  img {
    max-width: 100%;
  }

  &._4 {
    display: block;
  }
}

.orderGame {
  margin-bottom: 50px;
  margin-top: -40px;

  @media (min-width: $tablet) {
    scale: 0.75;
    margin-bottom: 20px;
    margin-top: -60px;
  }

  &__container {
  }

  &__block {
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    //border: 2px solid transparent;
    cursor: pointer;
    padding: 2px;

    &::before {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background: linear-gradient(to right, #D2B14F, #D86714);
      z-index: -1;

      @media (min-width:$mobileSmall){
        display: none;
      }
    }

    &._4 {
      &::before {
        content: '';
        display: none;
      }

      overflow: visible;
    }
  }

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    z-index: -1;
    border-radius: 12px;
    overflow: hidden;
    margin: 2px;

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: 1350px;
      height: 355px;
      object-fit: cover;
      object-position: center center;

      @media (max-width: $pc) {
        width: 1350px;
        height: 355px;
      }
      @media (max-width: $tablet) {
        width: 1000px;
        height: 305px;
      }
      @media (max-width: $mobile) {
        width: 700px;
        height: 230px;
      }
      @media (max-width: $mobileSmall) {
        //width: 405px;
        //height: 152px;
        //object-fit: cover;
        //left: auto;
        //transform: none;
        //right: 0;
        //
        //&._2 {
        //  width: 493px;
        //  height: 204px;
        //  right: -60px;
        //  top: -40px;
        //}

        width: 480px;
        height: 162px;
      }

      &._1 {
        @media (max-width: $mobileSmall) {
          left: auto;
          right: 0;
          transform: none;
          width: 455px;
        }

        @media (max-width: 400px) {
          width: 400px;
        }
      }

      &._3 {
        @media (max-width: $mobileSmall) {
          right: 0;
          object-fit: contain;
          width: 455px;
          height: auto;
          object-position: center center;
          top: 50%;
          left: 50%;
          transform: translateY(-45%) translateX(-50%);
        }

        @media (max-width: 400px) {
          width: 400px;
          left: 50%;
          transform: translateY(-45%) translateX(-53%);
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &._3 {
      justify-content: flex-end;
      max-width: 50%;
      margin-left: auto;
      @media (max-width: $mobile) {
        margin-right: 30px;
      }
      @media (max-width: $mobileSmall) {
        margin-right: 5px;
      }
    }
  }

  &__title {
    font-family: 'Scribble Serif';
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 0.9;
    position: relative;
    text-align: center;
    margin-top: 63px;
    margin-bottom: 55px;

    @media (max-width: $pc) {
      font-size: 100px;
      margin-bottom: 60px;
      margin-top: 63px;
    }
    @media (max-width: $tablet) {
      font-size: 82px;
      margin-bottom: 45px;
      margin-top: 63px;
    }
    @media (max-width: $mobile) {
      font-size: 46px;
      margin-bottom: 45px;
      margin-top: 53px;
    }
    @media (max-width: $mobileSmall) {
      font-size: 28px;
      margin-bottom: 26px;
      margin-top: 35px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
    }

    &._2 {
      margin-bottom: 50px;
      @media (max-width: $pc) {
        margin-bottom: 55px;
      }
      @media (max-width: $tablet) {
        margin-bottom: 40px;
      }
      @media (max-width: $mobile) {
        margin-bottom: 40px;
      }
      @media (max-width: $mobileSmall) {
        margin-top: 40px;
        margin-bottom: 24px;
        font-size: 30px;
      }
    }

    &._3 {
      text-wrap: auto;
      font-size: 30px;
      margin-bottom: 1px;
      margin-top: 55px;

      @media (max-width: $tablet) {
        margin-top: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      @media (max-width: $mobileSmall) {
        font-size: 18px;
        margin-bottom: 5px;
        margin-top: 19px;
      }
    }

    .title-orderGame__logo {
      position: absolute;
      left: 100%;
      top: 7px;
      margin-left: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 76px;
      height: 58px;

      @media (max-width: $pc) {
        width: 76px;
        height: 58px;
      }
      @media (max-width: $tablet) {
        width: 62px;
        height: 47px;
      }
      @media (max-width: $mobile) {
        width: 32px;
        height: 24px;
        margin-left: 5px;
        top: 5px;
      }
      @media (max-width: $mobileSmall) {
        width: 24px;
        height: 18px;
        top: 2px;
      }

      &._3 {
        width: 46px;
        height: 36px;
        top: auto;
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: $tablet) {
          position: relative;
          left: auto;
          top: auto;
          bottom: auto;
          right: auto;
          margin: 0 0 4px 0;
          width: 32px;
          height: 24px;
        }
        @media (max-width: $mobileSmall) {
          width: 18px;
          height: 15px;
        }
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__text {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 50px;

    @media (max-width: $pc) {
      font-size: 30px;
      margin-bottom: 50px;
    }
    @media (max-width: $tablet) {
      font-size: 24px;
      margin-bottom: 50px;
    }
    @media (max-width: $mobile) {
      font-size: 16px;
      margin-bottom: 34px;
    }
    @media (max-width: $mobileSmall) {
      font-size: 12px;
      margin-bottom: 21px;
    }

    &._2 {
      @media (max-width: $mobileSmall) {
        margin-bottom: 20px;
      }
    }

    &._3 {
      max-width: 332px;
      font-size: 33px;
      line-height: 1;
      margin-bottom: 9px;

      @media (max-width: $tablet) {
        font-size: 27px;
        line-height: 1.4;
        margin-bottom: 12px;
      }

      @media (max-width: $mobile) {
        font-size: 23px;
      }
      @media (max-width: $mobileSmall) {
        font-size: 14px;
        max-width: 150px;
      }
    }
  }

  &__btnBlock {
    margin-bottom: 10px;

    &._2 {
      @media (max-width: $mobileSmall) {
        margin-bottom: 9px;
      }
    }

    &._3 {
      margin-bottom: 16px;

      @media (max-width: $mobileSmall) {
        margin-bottom: 17px;
      }
    }
  }

  &__btn {
    width: 245px;
    height: 40px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;

    @include adaptiveValue("width", 245, 151);
    @include adaptiveValue("height", 40, 26);

    @media (max-width: $mobile) {
      font-size: 10px;
    }

    &._3 {
      width: 210px;
      height: 31px;
      font-size: 13px;

      @media (max-width: $mobileSmall) {
        width: 145px;
        height: 26px;
        font-size: 10px;
      }
    }
  }
}
</style>